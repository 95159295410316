.about-container {
  padding-top: 18%;
  background-color: #222930;
  color: #E9E9E9;
  width: 50%;
  margin: auto;
  text-align: left;
}

.about-button:hover {
  background-color: #3a4652 !important;
}

.navbar-button:hover {
    text-decoration: underline;
    font-weight: bold;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
}