.App {
  text-align: center;
  background-color: #222930;
  color: #E9E9E9;
  margin: auto;
}

.App-link {
  color: #61dafb;
}
