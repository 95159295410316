.h2-blinker {
  background-color: #222930;
  border-radius: 12px;
  padding: 10px 10px 10px 10px;
  height: inherit;
  margin: auto;
  color: #E9E9E9;
  font-family: 'Lucida Console';
  z-index: auto;
}

.appbar-homelink {
    text-decoration: none !important;
}

.appbar-homelink:hover {
  color: #bfbfbf;
}

.navbar-button {
  color: #E9E9E9;
  font-weight: bold;
}
.span-blinker {
  animation: blinkingText 1s infinite;
}

@keyframes blinkingText{
  0%{     color: #fff;    }
  49%{    color: #fff;    }
  60%{    color: transparent; }
  99%{    color: transparent; }
  100%{   color: #fff;    }
}